import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { feriadoStore } from '@/utils/store-accessor';
import { newFeriado } from '@/interfaces/feriado';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.entityObject = null;
        this.feriasDataFinal = null;
    }
    get feriadoTypeOptions() {
        return [
            'Férias',
            'Feriado Nacional',
            'Facultativo',
            'Feriado Municipal',
            'Feriado Estadual',
            'Evento'
        ];
    }
    async mounted() {
        if (this.$router.currentRoute.params.id) {
            // @ts-ignore
            this.entityObject = await feriadoStore.getFeriado(parseInt(this.$router.currentRoute.params.id, 0));
            this.feriasDataFinal = this.entityObject.data_final;
        }
        else {
            this.entityObject = newFeriado();
        }
    }
    async submit() {
        // const isValid = await this.$refs.observer.validate();
        // if (!isValid) {
        //   // @ts-ignore
        //   this.$refs.saveButton.changeLoadingStatus(false);
        //   return;
        // }
        this.entityObject.data_final = this.feriasDataFinal;
        if (this.id) {
            const result = await feriadoStore.updateFeriado(this.entityObject);
        }
        else {
            const result = await feriadoStore.createFeriado(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        await this.$router.push({ name: 'main-feriados' });
    }
};
Create = __decorate([
    Component({
        components: {
            SaveButton,
            DateFieldComponent,
        },
    })
], Create);
export default Create;
